import React, { useEffect, useRef } from 'react';

export const ReviveAd = ({ zoneId, reviveId }) => {
  const adContainerRef = useRef(null);

  useEffect(() => {
    const adContainer = adContainerRef.current;
    
    if (adContainer) {
      // Create the ins element
      const ins = document.createElement('ins');
      ins.setAttribute('data-revive-zoneid', zoneId);
      ins.setAttribute('data-revive-id', reviveId);

      // Create the script element
      const script = document.createElement('script');
      script.async = true;
      script.src = "//rv.sinarmasland.com/www/delivery/asyncjs.php";

      // Append elements
      adContainer.appendChild(ins);
      adContainer.appendChild(script);

      // Cleanup function
      return () => {
        adContainer.innerHTML = '';
      };
    }
  }, [zoneId, reviveId]);

  return <div ref={adContainerRef} className="revive-container"></div>;
};
